<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent
          class="py-8"
          title="1.6 Article External Fire Test"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="1.6 Article External Fire Test Details"
              size="medium"
            />
            <p class="p-light">
              A collection of articles as they appear for transport is placed on
              a non-combustible surface (steel grate) above a lattice of dried
              wood soaked with diesel fuel or equivalent source. A wire basket
              or clamps may be used to hold the articles in place. Sufficient
              fuel is used to provide a 30-minute fire.
            </p>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The 1.6 Article External Fire Test is completed to determine if
              the candidate explodes or reacts violently with fragments thrown
              more than 15 m as indicated by a reaction more violent than
              burning as delineated in Appendix 8 in the UN Manual of Tests and
              Criteria. Detonation, explosion, or fragments thrown more than 15
              m or other reaction more severe than burning (as described in
              Appendix 8 of the UN MTC) indicate the article is not a candidate
              as a 1.6 Article.
            </p>
            <TitleComponent class="py-8" title="Equivalence" size="medium" />
            <p class="p-light">
              Similar in test principle to
              <router-link class="link" to="/external-fire-test"
                >UN Series 6 (c)</router-link
              >, with the exception that if a single article has a volume larger
              than 0.15 cubic meters, only 1 article is required for the test.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objective</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Article quantity</td>
                    <td>
                      Provide a sufficient quantity of product to enable
                      characterization of any dangerous effect
                    </td>
                    <td></td>
                    <td>
                      Volume of the articles as offered for transport ≥ 0.15
                      m^3. If one article’s volume is greater than 0.15 m^3,
                      only one article is required during the fire.
                    </td>
                  </tr>
                  <tr>
                    <td>Product placement</td>
                    <td>
                      Ensure that the packages or articles are properly heated
                      by the fire
                    </td>
                    <td></td>
                    <td>
                      A metal grid is used to support the items above the fire.
                      For a wood fire, the items should be 1 m above the ground
                      while for a fuel fire 0.5 m above the ground.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Fuel type (wood, liquid, or gas), configuration, and
                      quantity
                    </td>
                    <td>
                      Create a fire of sufficient intensity and duration that
                      engulfs the product to enable characterization of any
                      dangerous effects
                    </td>
                    <td></td>
                    <td>
                      Enough fuel should be used to keep the fire burning for at
                      least 30 min or until the item(s) have clearly had enough
                      time to sufficiently react. The wood should extend beyond
                      the packages or articles by 1 m on each side. Likewise a
                      liquid or gas based fire should extend beyond the packages
                      by the same amount.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Witness screen size, placement, and materials of
                      construction
                    </td>
                    <td>
                      Evidence of fragment energy, and a standard reference
                      point for judging distances
                    </td>
                    <td></td>
                    <td>
                      Three 2m x 2m x 2mm 1100-0 Aluminum Sheets (Brinell
                      Hardness 23, Tensile Strength 90 MPa). The witness screens
                      should be placed 4 m from the edge of the packages or
                      articles.
                    </td>
                  </tr>
                  <tr>
                    <td>Limited Wind Speed</td>
                    <td>
                      Ensure that all the packages or articles are enveloped in
                      flames
                    </td>
                    <td></td>
                    <td>
                      The test should not be performed under conditions where
                      the wind speed exceeds 6 m/s.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Detonation</td>
                    <td>
                      Immediate consumption of the entire substance in the
                      article
                    </td>
                    <td>
                      Primary assessment is rapid plastic deformation of the
                      metal casing in contact with the explosive substance with
                      accompanied shock wave corresponding to mass and type of
                      the substance. Secondary assessment includes damage to
                      witness plate(s) and corresponding blast effects such as
                      craters or other damage to surroundings. NOT A 1.6 ARTICLE
                    </td>
                  </tr>
                  <tr>
                    <td>Partial detonation</td>
                    <td>
                      Immediate consumption of a portion of the entire substance
                      in the article
                    </td>
                    <td>
                      Primary assessment is rapid plastic deformation of a
                      portion of the metal casing in contact with the explosive
                      substance with accompanied shock wave corresponding to
                      less than that expected for the mass and type of the
                      substance. Secondary assessment includes damage to witness
                      plate(s) and corresponding blast effects such as craters
                      or other damage to surroundings. NOT A 1.6 ARTICLE
                    </td>
                  </tr>
                  <tr>
                    <td>Explosion</td>
                    <td>Rapid consumption of the substance in the article</td>
                    <td>
                      Primary assessment is fracturing of the metal casing
                      (larger pieces than seen in a detonation) in contact with
                      the explosive substance. Secondary assessment includes a
                      pressure wave of a smaller magnitude and longer duration
                      than seen in a detonation event with damage to witness
                      plate(s) and long-distance scattering of burning or
                      unburned pieces of the substance or article. NOT A 1.6
                      ARTICLE
                    </td>
                  </tr>
                  <tr>
                    <td>Deflagration</td>
                    <td>Combustion of the substance in the article</td>
                    <td>
                      Primary assessment is combustion (longer reaction time
                      than an explosion) of some or all of the substance with
                      rupture of the article into a few large pieces with at
                      least one piece traveling beyond 15m with energy &gt;20J.
                      Secondary assessment may include a pressure wave of a
                      small magnitude and long duration (less than seen in an
                      explosion event). NOT A 1.6 ARTICLE
                    </td>
                  </tr>
                  <tr>
                    <td>Burn</td>
                    <td>Burning of the substance in the article</td>
                    <td>
                      Primary assessment is a low pressure burn of some or all
                      of the substance in the article with likely associated
                      rupture of the article however no item travels beyond 15m
                      and does NOT have an associated energy &gt;20J. CANDIDATE
                      AS A 1.6 ARTICLE
                    </td>
                  </tr>
                  <tr>
                    <td>No Reaction</td>
                    <td>Substance in the article does not sustain burning</td>
                    <td>
                      Primary assessment is no sustained burning of the article
                      and no fragmentation of the article. CANDIDATE AS A 1.6
                      ARTICLE
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <SideBar class="w-full sm:w-1/5" />
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
